import emailjs from "@emailjs/browser";

AOS.init();

const btn = document.getElementById('input-submit');

btn.onclick = (event) => {
    event.preventDefault();

    btn.innerHTML = 'Senden...';
 
    const serviceID = "service_4x05zgu";
    const templateID = 'template_aqpjbgq';

    const publicKey = "IP1_NDQtFQHXTMKe_";

    var templateParams = {
        from_name: document.getElementById("input-name").value || "",
        message: document.getElementById("input-message").value || "",
        subject: document.getElementById("input-subject").value || "",
        from_mail: document.getElementById("input-email").value || ""
    };

    emailjs.send(serviceID, templateID,templateParams, publicKey)
     .then(() => {
        btn.innerHTML = 'Anfragen';

        document.getElementById("input-name").value = "";
        document.getElementById("input-message").value = "";
        document.getElementById("input-subject").value = "";
        document.getElementById("input-email").value = "";
     }, (err) => {
       btn.innerHTML = 'Anfragen';
       console.log(JSON.stringify(err));
     });
    
};
